'use client';

import { ProductCard, ProductCardProps } from '@components/Product/ProductCard';
import { ProductRow as ProductRowUI } from '@components/Product/ProductRow';
import { ProductRowHeading } from '@components/Product/ProductRowHeading';
import { TrackPromotionImpression } from '@components/TrackPromotionImpression/TrackPromotionImpression';
import { usePromoTrackingEvents } from '@components/TrackPromotionImpression/usePromoTrackingEvents';
import { useEntitlements } from '@contexts/EntitlementsContext';
import { useProductTags } from '@contexts/ProductTagsContext';
import { useRouter } from '@contexts/Router/RouterContext';
import { ProductRowFragment } from '@graphql/generated-contentful/graphql';
import { analytics } from '@lib/analytics/page-events';
import {
  getActiveSale,
  getDiscountPercent,
  getSalePrice,
} from '@lib/coveo/sale';
import { SaleType } from '@lib/types/Sale';
import { GetAssetByIdResponse } from '@lib/types/api/next-api/responses';
import { isDefined } from '@lib/util/isDefined';
import { getTagsDetailsBySlugs, mapContentfulTags } from '@lib/util/tags';
import { usePackagesByIds } from './usePackagesByIds';

export type ProductRowProps = {
  data: ProductRowFragment;
  position: number;
};

const ProductRow = ({ data, position }: ProductRowProps) => {
  const { locale } = useRouter();
  const {
    productRowTitle,
    productRowDescription,
    text,
    linkHref,
    linkTitle,
    productIdList,
    titleInternal,
    trackAsPromo,
  } = data;

  const productTags = useProductTags();
  const link =
    linkHref && linkTitle ? { href: linkHref, label: linkTitle } : undefined;
  const productIDs =
    productIdList
      ?.trim()
      .split(',')
      /**
       * The comma-separated IDs are defined in a LongText-field
       * they can include spaces and newlines, so we need to trim each item
       */
      .map((id) => id.trim())
      .filter((id) => !isNaN(Number(id))) || [];

  const { data: products, error, isLoading } = usePackagesByIds(productIDs);

  const { getUserEntitlement } = useEntitlements();

  // Function to map usePackagesByIds data to ProductCardProps
  const mapToProductCardProps = (data: GetAssetByIdResponse[]) => {
    return data
      .map((product: GetAssetByIdResponse): ProductCardProps | null => {
        const sale = product.sales ? getActiveSale(product.sales) : undefined;
        const productPrice = sale
          ? getSalePrice(sale, product.price.currency)
          : Number(product.price.originalPrice);

        const mappedProductTags = productTags
          ? mapContentfulTags(productTags)
          : [];

        const tag = product.tags
          ? getTagsDetailsBySlugs(product.tags, mappedProductTags)[0]
          : null;

        return {
          productId: product.id,
          name: product.name,
          imageUrl: product.thumbnail,
          publisher: product.publisher
            ? {
                name: product.publisher.name,
                id: product.publisher.publisherId,
              }
            : undefined,
          rating: product.rating.average,
          ratingCount: product.rating.count,
          price: {
            currentPrice: productPrice,
            originalPrice: Number(product.price.originalPrice),
            currency: product.price.currency,
            discountPercent: sale?.discount.value
              ? getDiscountPercent(sale)
              : undefined,
          },
          entitlement: getUserEntitlement(product.id),
          sale: sale
            ? {
                type: sale.type ?? SaleType.Sale,
                dates: {
                  starts: new Date(sale.dates.starts),
                  ends: new Date(sale.dates.ends),
                },
              }
            : undefined,
          label: tag
            ? {
                text: tag.label,
                iconName: tag.iconName,
              }
            : undefined,
          extraEventHandlers: {
            onClick: () => {
              analytics.pushProductClickEvent(
                {
                  product_id: product.id,
                  product_name: product.name,
                  product_price: productPrice,
                  product_publisher: product.publisher?.name ?? '',
                  product_publisher_id: product.publisher?.publisherId ?? '',
                  product_discount_percentage: sale
                    ? product.price.discountPercent
                    : 0,
                  product_discount: sale
                    ? Number(product.price.originalPrice) -
                      Number(product.price.currentPrice)
                    : 0,
                  product_is_on_sale: Boolean(sale),
                  product_is_free: productPrice == 0,
                },
                product.price.currency
              );
            },
          },
        };
      })
      .filter(isDefined);
  };

  const { promoClickTrackingEvent, promoViewTrackingEvent } =
    usePromoTrackingEvents({
      title: titleInternal || '',
      typename: 'ProductRow',
      position,
      trackAsPromo: trackAsPromo ?? false,
      ctas: link?.label ? [link.label] : [],
    });

  if (isLoading || error || !products || products.length === 0) {
    return null;
  }

  return (
    <TrackPromotionImpression
      onImpression={promoViewTrackingEvent}
      shouldTrack={trackAsPromo ?? false}
    >
      <ProductRowHeading
        title={productRowTitle}
        description={productRowDescription}
        text={text}
        link={link}
        onLinkClick={promoClickTrackingEvent}
      />
      <ProductRowUI>
        {mapToProductCardProps(products).map((product) => (
          <ProductCard
            key={product.productId}
            className="mr-0 min-w-[242px] md:min-w-full"
            locale={locale}
            {...product}
          />
        ))}
      </ProductRowUI>
    </TrackPromotionImpression>
  );
};

export default ProductRow;
