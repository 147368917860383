import { useMicrocopy } from '@contexts/MicrocopyContext';
import { MicrocopyFragment } from '@graphql/generated-contentful/graphql';
import { SaleType } from '@lib/types/Sale';
import { Entitlement } from '@lib/types/api/Entitlement';
import c from 'classnames';
import Image from 'next/image';
import React from 'react';
import {
  Label,
  Link,
  Stars,
  Tooltip,
  TooltipArrow,
  useIsTextTruncated,
  useTooltip,
} from 'ui-components';
import { DEFAULT_LOCALE, interpolateStringWithData } from 'utils';
import { ProductCardPriceSection } from './ProductCardPriceSection';
import { ProductCardSaleInfo } from './ProductCardSaleInfo';
import { Price, Sale } from './types';
import { useSaleState } from './useIsActiveSale';

export type ProductCardProps = {
  name: string;
  productId: string;
  imageUrl: string;
  publisher?: {
    name: string;
    id: string;
  };
  rating?: number;
  ratingCount?: number;
  price: Price;
  sale?: Sale;
  label?: {
    text: string;
    iconName?: string;
  };
  entitlement?: Entitlement;
  locale?: string;
  onClick?: () => void;
  extraEventHandlers?: {
    onClick?: () => void;
    onMouseDown?: () => void;
    onMouseUp?: () => void;
    onContextMenu?: () => void;
    onTouchStart?: () => void;
    onTouchEnd?: () => void;
  };
  className?: string;
};

export const ProductCard = ({
  name,
  productId,
  imageUrl,
  publisher,
  rating = 0,
  ratingCount = 0,
  price,
  sale,
  label,
  entitlement,
  locale = DEFAULT_LOCALE,
  extraEventHandlers = {},
  className = '',
}: ProductCardProps) => {
  const microcopy = useMicrocopy<{
    productCardMicrocopy: MicrocopyFragment[];
  }>();
  const purchasedMicrocopy =
    microcopy?.productCardMicrocopy?.find(
      (microcopyFragment) => microcopyFragment.key === 'product-card.purchased'
    )?.value ?? 'Purchased';
  const purchasedSeatsMicrocopy =
    microcopy?.productCardMicrocopy?.find(
      (microcopyFragment) =>
        microcopyFragment.key === 'product-card.purchased-seats'
    )?.value ?? 'Purchased Seat(s)';
  const ratingMicrocopy =
    microcopy?.productCardMicrocopy?.find(
      (microcopyFragment) =>
        microcopyFragment.key === 'product-card.star-rating-label'
    )?.value ?? 'Rating: {{rating}} out of 5 stars';
  const { active, upcoming, type } = useSaleState(sale);

  // Tooltip for long product names that are truncated
  const {
    context,
    isOpen,
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    arrowRef,
  } = useTooltip({
    offset: 0,
    arrow: true,
  });

  // Check if the text is truncated
  const isTextTruncated = useIsTextTruncated(
    refs.reference as React.RefObject<HTMLDivElement>
  );

  return (
    <article
      data-test={`search-results-product-card-${productId}`}
      className={c(
        'relative block rounded-2xl border border-grey-200 p-0 lg:-mx-5 lg:border-none lg:p-5 lg:pb-6 lg:hover:z-10 lg:hover:shadow-card',
        className
      )}
    >
      <div className="relative aspect-3/2">
        {entitlement && (
          <Label
            testId="product-card-purchased-label"
            appearance="item"
            text={
              entitlement.type === 'SERVICE'
                ? purchasedMicrocopy
                : purchasedSeatsMicrocopy
            }
            className="absolute z-10 mt-2 text-sm normal-case tracking-normal"
          />
        )}
        <Image
          fill
          sizes="50vw, (min-width: 768px) 25vw, (min-width: 1024px) 33vw"
          alt=""
          aria-hidden={true}
          className={c(
            'rounded-t-2xl object-cover lg:rounded-t-lg',
            entitlement && 'opacity-60'
          )}
          src={imageUrl}
        />
      </div>
      <div className="p-2 pb-3 lg:px-2 lg:pb-0 lg:pt-3">
        <div className="flex flex-col">
          <Link
            href={`/packages/package/${productId}`}
            data-test="product-card-name"
            className="focus-outline rounded after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 "
            // after pseudo-element makes the whole card clickable
            // wrapping the entire card in a link would result in invalid HTML with a nested link for publisher inside
            {...extraEventHandlers}
          >
            <span
              className="relative z-10 mb-2 line-clamp-2 text-sm font-medium tracking-tight text-grey-700 lg:text-base"
              ref={refs.setReference}
              {...getReferenceProps()}
            >
              {name}
            </span>
          </Link>
          {isOpen && isTextTruncated && (
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <TooltipArrow arrowRef={arrowRef} context={context} />
              <Tooltip label={name} className="max-w-56" />
            </div>
          )}
          {rating ? (
            <div
              data-test="product-card-rating"
              className="mb-1 flex items-center gap-1 text-xxs/5 lg:text-base/6"
            >
              <Stars
                value={rating}
                width={16}
                ariaLabel={interpolateStringWithData(ratingMicrocopy, {
                  rating: rating.toString(),
                })}
              />
              <div className="text-grey-600" data-test="product-rating-count">
                ({ratingCount})
              </div>
            </div>
          ) : null}
          {publisher && (
            <Link
              data-test="product-card-publisher"
              className="caption-regular focus-outline relative z-10 overflow-hidden text-ellipsis whitespace-nowrap rounded text-xxs/5 text-grey-600 lg:text-sm"
              href={`/publishers/${publisher.id}`}
            >
              {publisher.name}
            </Link>
          )}
          <ProductCardPriceSection price={price} sale={sale} />
          <div className="mt-3 flex flex-col flex-wrap justify-between gap-2 lg:flex-row lg:items-center">
            {(active || upcoming) && sale ? (
              <ProductCardSaleInfo
                sale={sale}
                isFlashDealActive={
                  active && type === SaleType.FlashDealTimeBased
                }
                isFlashDealUpcoming={
                  upcoming && type === SaleType.FlashDealTimeBased
                }
                locale={locale}
              />
            ) : null}

            {label && (
              <Label
                appearance="editorial"
                iconName={label.iconName}
                text={label.text}
                testId={`product-card-tag-${label.text.replace(' ', '-')}`}
                className="text-nowrap text-xxs font-medium tracking-[0.1rem] xl:text-xs"
              />
            )}
          </div>
        </div>
      </div>
    </article>
  );
};
