import { useMicrocopy } from '@contexts/MicrocopyContext';
import { MicrocopyFragment } from '@graphql/generated-contentful/graphql';
import c from 'classnames';
import {
  DEFAULT_LOCALE,
  formatDisplayableCurrency,
  interpolateStringWithData,
} from 'utils';

const getMicrocopyValues = (microcopy?: MicrocopyFragment[]) => {
  const freeMicrocopy = microcopy?.find(
    (microcopyFragment) => microcopyFragment.key === 'product-card.free'
  )?.value;
  const onSaleAriaMicrocopy = microcopy?.find(
    (microcopyFragment) =>
      microcopyFragment.key === 'product-card.on-sale-aria-label'
  )?.value;
  const originalAriaMicrocopy = microcopy?.find(
    (microcopyFragment) =>
      microcopyFragment.key === 'product-card.original-price-aria-label'
  )?.value;

  return {
    freeMicrocopy: freeMicrocopy ?? 'Free',
    onSaleAriaMicrocopy: onSaleAriaMicrocopy ?? 'On sale for: {{price}}',
    originalAriaMicrocopy: originalAriaMicrocopy ?? 'Original price: {{price}}',
  };
};

type ProductCardPriceProps = {
  price: {
    currentPrice: number;
    originalPrice: number;
    currency?: string;
    discountPercent?: number;
  };
  isSaleActive: boolean;
  isFlashDealActive: boolean;
  className?: string;
};

export const ProductCardPrice = ({
  isSaleActive,
  isFlashDealActive,
  price,
  className = '',
}: ProductCardPriceProps) => {
  const microcopy = useMicrocopy<{
    productCardMicrocopy: MicrocopyFragment[];
  }>();
  const productCardMicrocopy = microcopy?.productCardMicrocopy;
  const { freeMicrocopy, onSaleAriaMicrocopy, originalAriaMicrocopy } =
    getMicrocopyValues(productCardMicrocopy);
  const currentPriceFormatted = formatDisplayableCurrency(
    isSaleActive ? price.currentPrice : price.originalPrice,
    DEFAULT_LOCALE,
    price.currency
  );
  const originalPriceFormatted = formatDisplayableCurrency(
    price.originalPrice,
    DEFAULT_LOCALE,
    price.currency
  );

  return (
    <div
      className={c(
        'body flex flex-col gap-x-2 lg:flex-row lg:items-center',
        className
      )}
    >
      {!price.currentPrice ? (
        <div data-test="product-card-current-price">{freeMicrocopy}</div>
      ) : (
        <>
          {isSaleActive ? (
            <span className="sr-only">
              {interpolateStringWithData(onSaleAriaMicrocopy, {
                price: currentPriceFormatted,
              })}
            </span>
          ) : null}
          <div
            data-test="product-card-current-price"
            aria-hidden={isSaleActive ? true : false}
            className={c({ 'text-red-600': isFlashDealActive })}
          >
            {currentPriceFormatted}
          </div>
        </>
      )}
      {isSaleActive ? (
        <>
          <span className="sr-only">
            {interpolateStringWithData(originalAriaMicrocopy, {
              price: originalPriceFormatted,
            })}
          </span>
          <div
            aria-hidden="true"
            data-test="product-card-original-price"
            className="text-grey-600 line-through"
          >
            {originalPriceFormatted}
          </div>
        </>
      ) : null}
    </div>
  );
};
