'use client';

import {
  SortCriterion,
  loadAdvancedSearchQueryActions,
  loadQueryActions,
  loadSortCriteriaActions,
} from '@coveo/atomic-react';
import React, { createContext, useEffect } from 'react';

import { CoveoSearchHub } from '@lib/coveo/coveoConstants';
import '../../styles/coveoCustomCss.css';
import { AtomicContextValue } from './AtomicContext';
import { useCoveoHeadlessEngine } from './useCoveoHeadlessEngine';

export const AtomicListingContext = createContext<AtomicContextValue>({
  searchEngine: undefined,
});

/**
 * This Context provides the Coveo Atomic Listing Engine to all children components.
 * The coveo engine is a redux store that can be passed to Atomic components, and used to access any part of the Coveo state.
 *
 * This AtomicListingContext should be used to wrap the specific components that directly use it, ie, Product Rows.
 * This is because there can be multiple listings on one page at a time, and we don't want to share the same engine across multiple listings.
 *
 * The AtomicSearchContext, is used globally because it is used in the Header for the searchbox, and on the search page.
 */
export const AtomicListingProvider = ({
  uniqueId,
  queryExpression,
  numberOfResults,
  sortCriteria,
  searchQuery,
  children,
}: React.PropsWithChildren<{
  uniqueId: string;
  queryExpression: string;
  sortCriteria: SortCriterion | SortCriterion[];
  searchQuery?: string;
  numberOfResults: number;
}>) => {
  const value = useCoveoHeadlessEngine(
    CoveoSearchHub.LISTING,
    `${CoveoSearchHub.LISTING}_${uniqueId}`,
    numberOfResults
  );

  useEffect(() => {
    if (value.searchEngine) {
      const updateAdvancedSearchQueries = loadAdvancedSearchQueryActions(
        value.searchEngine
      ).updateAdvancedSearchQueries({
        aq: queryExpression,
      });
      value.searchEngine.dispatch(updateAdvancedSearchQueries);

      const updateSortCriteria = loadSortCriteriaActions(
        value.searchEngine
      ).registerSortCriterion(sortCriteria);
      value.searchEngine.dispatch(updateSortCriteria);

      if (searchQuery) {
        const updateQueryAction = loadQueryActions(
          value.searchEngine
        ).updateQuery({ q: searchQuery });
        value.searchEngine.dispatch(updateQueryAction);
      }
    }
  }, [value, queryExpression, sortCriteria, searchQuery]);

  return (
    <AtomicListingContext.Provider value={value}>
      {children}
    </AtomicListingContext.Provider>
  );
};
