'use client';

import { validateEmailFormat } from '@lib/util/forms';
import { FormErrorMessage } from '@ui-components/components/Form/FormErrorMessage';
import Image from 'next/image';
import { FormEvent, useState } from 'react';
import { Button, Checkbox, Dropdown, Input } from 'ui-components';
import { countries } from 'utils/src/countries';
import xss from 'xss';

type EmailSignupProps = {
  submitForm: (email: string, country: string) => Promise<void>;
  className?: string;
  heading: string;
  inputPlaceholder: string;
  inputClearLabel?: string;
  inputErrorLabel: string;
  checkboxLabel: string;
  checkboxErrorLabel: string;
  countryDropdownLabel: string;
  countryDropdownErrorLabel: string;
  submitButtonLabel: string;
  submittedHeading: string;
  submittedText: string;
  legalText: string;
  unexpectedErrorLabel: string;
  backgroundImageUrl?: string;
};

type EmailSignupErrors = {
  input: null | string;
  checkbox: null | string;
  dropdown: null | string;
  formSubmission: null | string;
};

const defaultErrorStates = {
  input: null,
  checkbox: null,
  dropdown: null,
  formSubmission: null,
};

export const EmailSignup = ({
  submitForm,
  heading,
  inputPlaceholder,
  inputClearLabel,
  inputErrorLabel,
  checkboxLabel,
  checkboxErrorLabel,
  countryDropdownLabel,
  countryDropdownErrorLabel,
  submitButtonLabel,
  legalText,
  submittedHeading,
  submittedText,
  unexpectedErrorLabel,
  backgroundImageUrl,
}: EmailSignupProps) => {
  const [email, setEmail] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [errors, setErrors] = useState<EmailSignupErrors>(defaultErrorStates);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [activeCountry, setActiveCountry] = useState({
    value: 'default',
    label: countryDropdownLabel || 'Select country',
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let newErrors: EmailSignupErrors = defaultErrorStates;

    if (!validateEmailFormat(email)) {
      newErrors = {
        ...newErrors,
        input: inputErrorLabel,
      };
    }

    if (countryDropdownLabel && activeCountry.value === 'default') {
      newErrors = {
        ...newErrors,
        dropdown: countryDropdownErrorLabel || 'Please select a country',
      };
    }

    if (!isCheckboxChecked) {
      newErrors = {
        ...newErrors,
        checkbox: checkboxErrorLabel,
      };
    }

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
    } else {
      try {
        await submitForm(email, activeCountry.value);
        setIsFormSubmitted(true);
      } catch (error) {
        setErrors({
          ...defaultErrorStates,
          formSubmission: error.message,
        });
      }
    }
  };

  return (
    <div className="relative flex min-h-96 flex-col justify-center overflow-hidden bg-black py-14">
      {backgroundImageUrl && (
        <Image
          fill
          alt=""
          src={backgroundImageUrl}
          className="pointer-events-none scale-110 object-cover opacity-20 blur-md"
        />
      )}
      <div className="main-grid relative z-10">
        {isFormSubmitted ? (
          <div
            data-test="email-signup-form-submitted"
            className="col-span-4 md:col-span-10 md:col-start-2 lg:col-span-7 lg:col-start-3"
          >
            <h2 className="header-mid mb-8 text-balance text-white">
              {submittedHeading}
            </h2>
            <p
              className="body text-white"
              dangerouslySetInnerHTML={{ __html: xss(submittedText) }}
            />
          </div>
        ) : (
          <form
            data-test="email-signup-form"
            className="col-span-4 md:col-span-10 md:col-start-2 lg:col-span-7 lg:col-start-3"
            // rely on our validation rather than the built in HTML form validation that doesn't always work as expected
            noValidate
            onSubmit={onSubmit}
          >
            <h2 className="header-mid mb-8 text-balance text-white">
              {heading}
            </h2>
            <div className="flex flex-col flex-wrap md:flex-row">
              <Input
                id="newsletter-signup-email"
                name="newsletter-signup-email"
                placeholder={inputPlaceholder}
                accessibleLabel={inputPlaceholder}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onClear={() => setEmail('')}
                clearInputLabel={inputClearLabel}
                error={errors.input ? inputErrorLabel : undefined}
                className="order-first mb-8 md:w-110 xl:w-116"
              />
              {countryDropdownLabel && (
                <Dropdown
                  id="newsletter-signup"
                  options={countries.map((country) => ({
                    value: country.value,
                    label: country.label,
                  }))}
                  selectedOption={
                    activeCountry && {
                      value: activeCountry.value,
                      label: activeCountry.label,
                    }
                  }
                  onSelect={(option) => setActiveCountry(option)}
                  ariaLabelledBy={`dropdown-newsletter-signup-email-option-label`}
                  maxDropdownHeight={170}
                  error={
                    errors.dropdown ? countryDropdownErrorLabel : undefined
                  }
                  className="relative z-10 mb-8 w-full md:w-110 xl:w-116"
                  isDarkTheme={true}
                  dropdownStyle="newsletter"
                />
              )}
              <Checkbox
                id="newsletter-signup-checkbox"
                label={checkboxLabel}
                isChecked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                error={errors.checkbox ? checkboxErrorLabel : undefined}
                className="mb-8 w-full"
              />
              {errors.formSubmission && (
                <FormErrorMessage
                  inputId="form"
                  errorMessage={unexpectedErrorLabel}
                  className="mt-4 w-full"
                />
              )}
              <div
                className="tiny-text order-last mb-8 w-full text-grey-500 "
                dangerouslySetInnerHTML={{ __html: xss(legalText) }}
              />
              <Button
                type="submit"
                size="medium"
                label={submitButtonLabel}
                className="order-1 mb-8 h-fit flex-1 md:order-first md:ml-3 md:flex-none"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
