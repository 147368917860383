import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Scalars } from '@graphql/generated-contentful/graphql';
import { Maybe } from '@lib/types/Maybe';
import c from 'classnames';

import { RichText } from 'ui-components';

type BlocksTitleProps = {
  title?: Maybe<string>;
  description?: Scalars['JSON']['output'];
  text?: Scalars['JSON']['output'];
  isDarkTheme?: boolean;
};
export const BlocksTitle = ({
  title,
  description,
  text,
  isDarkTheme = false,
}: BlocksTitleProps) => {
  if (text) {
    const textHtml = documentToHtmlString(text?.json);
    const textHtmlWithLinebreaks = textHtml.replaceAll('<p></p>', '</br>');

    return (
      <div className={c({ 'text-white': isDarkTheme })}>
        <RichText html={textHtmlWithLinebreaks} className="block [&_p]:mb-0" />
      </div>
    );
  }

  const descHtml = documentToHtmlString(description?.json);
  const descHtmlWithLinebreaks = descHtml.replaceAll('<p></p>', '</br>');

  return (
    <div className={c({ 'text-white': isDarkTheme })}>
      {title && (
        <h2 data-test="blocks-title" className="header-mid">
          {title}
        </h2>
      )}
      {description && (
        <span
          data-test="blocks-title-description"
          className="mt-5 block"
          dangerouslySetInnerHTML={{ __html: descHtmlWithLinebreaks }}
        />
      )}
    </div>
  );
};
