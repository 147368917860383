'use client';
import { AtomicSearchInterfaceWrapper } from '@components/Search/AtomicSearchInterfaceWrapper';
import { TrackPromotionImpression } from '@components/TrackPromotionImpression/TrackPromotionImpression';
import { usePromoTrackingEvents } from '@components/TrackPromotionImpression/usePromoTrackingEvents';
import { EntitlementsProvider } from '@contexts/EntitlementsContext';
import {
  AtomicListingContext,
  AtomicListingProvider,
} from '@contexts/coveo/AtomicListingContext';
import { SortBy, SortCriterion } from '@coveo/atomic-react';
import { DynamicProductListingFragment } from '@graphql/generated-contentful/graphql';
import {
  DynamicProductListingSaleFilter,
  buildQueryExpressionWithCMSInputs,
  convertCMSSortOrderToCoveoCriteria,
} from '@lib/util/coveo/cmsToCoveoFieldConversions';
import { toNonNullable } from '@lib/util/toNonNullable';
import { formatTitleForID } from 'utils';
import { DynamicProductListingCards } from './DynamicProductListingCards';
import { DynamicProductListingHeading } from './DynamicProductListingHeading';

const DEFAULT_NUMBER_OF_PRODUCTS = 4;

export type DynamicProductListingProps = {
  data: DynamicProductListingFragment;
  position: number;
};

const DynamicProductListing = ({
  data,
  position,
}: DynamicProductListingProps) => {
  const {
    dynamicProductListingTitle,
    dynamicProductListingTitleInternal,
    dynamicProductListingDescription,
    text,
    maxNumberOfProducts,
    categoriesFilter,
    priceMaximumFilter,
    priceMinimumFilter,
    publishersFilter,
    ratingFilter,
    saleFilter,
    unityVersionFilter,
    sortOrder,
    searchQuery,
    linkTitle,
    linkHref,
    trackAsPromo,
  } = data;

  const queryExpression = buildQueryExpressionWithCMSInputs({
    categories: toNonNullable(categoriesFilter),
    priceMin: toNonNullable(priceMinimumFilter),
    priceMax: toNonNullable(priceMaximumFilter),
    publishers: toNonNullable(publishersFilter),
    rating: toNonNullable(ratingFilter),
    sales: toNonNullable(saleFilter)
      ? (saleFilter as DynamicProductListingSaleFilter[])
      : undefined,
    unityVersion: toNonNullable(unityVersionFilter),
  });
  const sortCriteria: SortCriterion | SortCriterion[] = sortOrder
    ? convertCMSSortOrderToCoveoCriteria(sortOrder)
    : { by: SortBy.Relevancy };
  const uniqueId = `DynamicProductListing-${dynamicProductListingTitleInternal ? formatTitleForID(dynamicProductListingTitleInternal) : ''}`;
  const link =
    linkHref && linkTitle ? { href: linkHref, label: linkTitle } : undefined;

  const { promoClickTrackingEvent, promoViewTrackingEvent } =
    usePromoTrackingEvents({
      title: dynamicProductListingTitleInternal || '',
      typename: 'DynamicProductListing',
      position,
      trackAsPromo: trackAsPromo ?? false,
      ctas: link?.label ? [link.label] : [],
    });

  return (
    <TrackPromotionImpression
      onImpression={promoViewTrackingEvent}
      shouldTrack={trackAsPromo ?? false}
    >
      <AtomicListingProvider
        uniqueId={uniqueId}
        queryExpression={queryExpression}
        sortCriteria={sortCriteria}
        searchQuery={toNonNullable(searchQuery)}
        numberOfResults={maxNumberOfProducts ?? DEFAULT_NUMBER_OF_PRODUCTS}
      >
        <AtomicSearchInterfaceWrapper
          shouldExecuteFirstSearch
          reflectStateInUrl={false}
          context={AtomicListingContext}
          id={uniqueId}
        >
          <DynamicProductListingHeading
            title={dynamicProductListingTitle}
            description={dynamicProductListingDescription}
            text={text}
            link={link}
            onLinkClick={promoClickTrackingEvent}
          />
          <EntitlementsProvider>
            <DynamicProductListingCards />
          </EntitlementsProvider>
        </AtomicSearchInterfaceWrapper>
      </AtomicListingProvider>
    </TrackPromotionImpression>
  );
};

export default DynamicProductListing;
