import { ProductRow } from '@components/Product/ProductRow';
import { HeadlessProductCardWrapper } from '@components/Search/HeadlessProductCardWrapper';
import { useResults } from '@components/Search/hooks/useResults';
import { useProductTags } from '@contexts/ProductTagsContext';
import { AtomicListingContext } from '@contexts/coveo/AtomicListingContext';
import { mapContentfulTags } from '@lib/util/tags';
import { useContext } from 'react';

export const DynamicProductListingCards = () => {
  const { searchEngine } = useContext(AtomicListingContext);
  const { results: resultState } = useResults(searchEngine);
  const productTags = useProductTags();
  const mappedProductTags = productTags ? mapContentfulTags(productTags) : [];

  if (!resultState?.results || resultState?.results.length === 0) {
    return null;
  }

  return (
    <ProductRow>
      {resultState?.results.map((result) => (
        <HeadlessProductCardWrapper
          key={result.raw.ec_product_id as string}
          context={AtomicListingContext}
          result={result}
          allProductTags={mappedProductTags}
          className="mr-0 min-w-[242px] md:min-w-full lg:-mr-5"
        />
      ))}
    </ProductRow>
  );
};
