import {
  SortBy,
  SortCriterion,
  SortOrder,
  buildQueryExpression,
} from '@coveo/atomic-react';
import { SaleType } from '@lib/types/Sale';

export type DynamicProductListingSaleFilter =
  | 'On Sale'
  | 'New Release Discount'
  | 'Flash Deal';

/*
 * Using filters set in the Contentful CMS, build a Coveo query expression to filter the search results.
 * Coveo syntax query reference: https://docs.coveo.com/en/1552/searching-with-coveo/query-syntax#field-queries
 */
export const buildQueryExpressionWithCMSInputs = ({
  categories,
  priceMax,
  priceMin,
  publishers,
  rating,
  sales,
  unityVersion,
}: {
  categories?: string;
  priceMax?: number;
  priceMin?: number;
  publishers?: string;
  rating?: number;
  sales?: DynamicProductListingSaleFilter[];
  unityVersion?: string;
}): string => {
  const queryExpression = buildQueryExpression();

  if (categories) {
    // coveo expects subcategories to be separated by ' > '
    const categoriesArray =
      categories
        .split(',')
        .map((category) => category.replaceAll('/', ' > ').trim()) ?? [];
    queryExpression.addStringField({
      field: 'ec_category',
      operator: 'isExactly',
      values: categoriesArray,
    });
  }

  if (priceMin) {
    queryExpression.addNumericField({
      field: 'ec_price_filter',
      operator: 'greaterThanOrEqual',
      value: priceMin,
    });
  }

  if (priceMax || priceMax === 0) {
    queryExpression.addNumericField({
      field: 'ec_price_filter',
      operator: 'lowerThanOrEqual',
      value: priceMax,
    });
  }

  if (publishers) {
    const publishersArray =
      publishers
        ?.split(',')
        .map((publisherIdString) => publisherIdString.trim()) ?? [];
    queryExpression.addStringField({
      field: 'publisher_id',
      operator: 'isExactly',
      values: publishersArray,
    });
  }

  if (rating) {
    queryExpression.addNumericField({
      field: 'ec_rating',
      operator: 'greaterThanOrEqual',
      value: rating,
    });
  }

  if (sales && sales.length > 0) {
    const saleTypes = sales.map((sale) => {
      switch (sale) {
        case 'New Release Discount':
          return SaleType.NewReleaseDiscount;
        case 'Flash Deal':
          return 'flash_deal';
        case 'On Sale':
        default:
          return SaleType.Sale;
      }
    });
    queryExpression.addStringField({
      field: 'ec_sale_filters',
      operator: 'isExactly',
      values: saleTypes,
    });
  }

  if (unityVersion) {
    const END_OF_UNITY_VERSION = '99';
    const unityVersionNumber = parseInt(
      unityVersion.concat(END_OF_UNITY_VERSION),
      10
    );
    queryExpression.addNumericRangeField({
      field: 'min_unity_version',
      from: 0,
      to: unityVersionNumber,
    });
  }

  return queryExpression.toQuerySyntax();
};

export const convertCMSSortOrderToCoveoCriteria = (
  sortOrder: string
): SortCriterion | SortCriterion[] => {
  switch (sortOrder) {
    case 'Rating':
      return [
        {
          by: SortBy.Field,
          field: 'ec_rating',
          order: SortOrder.Descending,
        },
        {
          by: SortBy.Field,
          field: 'ec_rating_count',
          order: SortOrder.Descending,
        },
      ];
    case 'Published Date':
      return {
        by: SortBy.Field,
        field: 'first_published_at',
        order: SortOrder.Descending,
      };
    case 'Recently Updated':
      return {
        by: SortBy.Field,
        field: 'last_published_at',
        order: SortOrder.Descending,
      };
    case 'Price (Low to High)':
      return {
        by: SortBy.Field,
        field: 'ec_price_filter',
        order: SortOrder.Ascending,
      };
    case 'Price (High to Low)':
      return {
        by: SortBy.Field,
        field: 'ec_price_filter',
        order: SortOrder.Descending,
      };
    case 'Relevance':
    default:
      return {
        by: SortBy.Relevancy,
      };
  }
};
